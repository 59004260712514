<template>
  <div>
    <el-upload
        :action="action"
        list-type="picture-card"
        :auto-upload="false"
        :limit="limit"
        v-bind="$attrs"
        :file-list="fileList"
        @change="afterRead"
        :class="{ unShowUploadImg: isLimitReached }"
        :style="{
        '--height': `${height}px`,
        '--width': `${width}px`
      }"
    >
      <i class="el-icon-plus"></i>

      <template slot="file" slot-scope="{ file }">
        <div :style="{ height: `${height}px`, width: `${width}px` }">
          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
          <span class="el-upload-list__item-actions">
            <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
              <i class="el-icon-zoom-in"></i>
            </span>
            <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </template>
      <template slot="tip">
        <div class="color-{#999} text-12">{{ tip }}</div>
      </template>
    </el-upload>
    <div class="cropper-box" v-if="cropperOption.img && show">
      <vue-cropper
          ref="cropper"
          :img="cropperOption.img"
          :auto-crop="cropperOption.autoCrop"
          :fixedNumber="[cropperOption.autoCropWidth, cropperOption.autoCropHeight]"
          :fixed="true"
          :centerBox="true"
      />
      <div class="flex jc-center bgc-{#fff} py-15">
        <ch-button type="cancel" @click="cancelUpload">取消上传</ch-button>
        <ch-button type="ok" @click="handleFileChange" :loading="loadingCrop">确定上传</ch-button>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img :src="dialogImageUrl" style="object-fit: contain; max-width: 100%"  alt=""/>
    </el-dialog>
  </div>
</template>

<script>
import req from '@/components/Upload/request'
import { Message } from 'element-ui'
import Compressor from 'compressorjs'
import {VueCropper} from 'vue-cropper'

export default {
  components: {
    VueCropper,
  },
  props: {
    action: {
      type: String,
      required: true
    },
    limit: {
      type: Number
    },
    uploadResponse: {
      type: Function
    },
    removeResponse: {
      type: Function
    },
    loading: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 80
    },
    width: {
      type: Number,
      default: 80
    },
    tip: {
      type: String,
      default: ''
    },
    compress: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      fileList: this.value,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      show: false,
      curFile: '',
      loadingCrop: false,
      cropperOption: {
        img: '',
        autoCrop: true,
        autoCropWidth: this.width,
        autoCropHeight: this.height,
        fixedBox: true
      }
    }
  },
  mounted() {
    console.log('Component mounted.')
  },
  computed: {
    isLimitReached() {
      return this.fileList.length >= this.limit
    }
  },
  watch: {
    value(newVal) {
      this.fileList = newVal
    },
    fileList(newVal) {
      this.$emit('input', newVal)
    }
  },
  methods: {
    handleRemove(file) {
      this.fileList = this.fileList.filter((item) => item.uid !== file.uid)
      this.removeResponse && this.removeResponse()
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    getCompressorImageUrl(file) {
      return new Promise((resolve) => {
        new Compressor(file, {
          quality: 0.8,
          mimeType: 'image/jpeg',
          success: (result) => {
            this.blobToDataURL(result, (base64) => {
              resolve(base64)
            })
          }
        })
      })
    },
    blobToDataURL(blob, callback) {
      let a = new window.FileReader()
      a.onload = function(e) {
        callback(e.target.result)
      }
      a.readAsDataURL(blob)
    },
    getImgSize(base64) {
      const dataChars = base64.replace(/\s+/g, '').replace(/=+$/, '')
      return (((dataChars.length / 4) * 3) / 1024 / 1024).toFixed(2)
    },
    base64toFilelet(dataurl, filename = 'file') {
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let suffix = mime.split('/')[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      })
    },
    afterRead(file, fileList) {
      const isJPG = /\.(jpeg|jpg|png|svg)$/i.test(file.name)
      if (!isJPG) {
        this.fileList = fileList.pop()
        return Message.error('请上传图片文件')
      }
      this.curFile = file
      this.blobToDataURL(file.raw, (base64) => {
        console.log(base64,'base64')
        this.cropperOption.img = base64
        this.show = true
      })
    },
    cancelUpload() {
      this.cropperOption.img = ''
      this.show = false
      this.curFile = ''
      this.fileList.pop()
    },
    handleFileChange() {
      const file = this.curFile
      this.loadingCrop = true
      this.$refs.cropper.getCropData(async (data) => {
        this.fileList[this.fileList.length - 1].url = data
        const newFile = await this.base64toFilelet(data, file.name)
        // const newFile = await this.base64toFilelet(file.url, fileName)

        if (this.compress) {
          this.$emit('update:loading', true)
          const formData = new FormData()
          formData.append('file', newFile)
          req.upload(this.action, formData)
              .then((res) => {
                this.uploadResponse && this.uploadResponse(res, file)
              })
              .catch(() => {
                this.handleRemove(file)
              })
              .finally(() => {
                this.$emit('update:loading', false)
                this.loadingCrop = false
                this.show = false
                this.cropperOption.img = ''
              })
          return
        }
        let UploadFile = newFile
        let compressorImageUrl = ''
        await this.getCompressorImageUrl(UploadFile).then((res) => {
          compressorImageUrl = res
        })
        newFile.url = compressorImageUrl
        this.fileList[this.fileList.length - 1].url = compressorImageUrl
        const size = this.getImgSize(compressorImageUrl)
        if (size >= 5) {
          this.handleRemove(newFile)
          return Message.error('上传图片压缩后大于5M，请重新上传')
        }
        const canvas = document.createElement('canvas')
        const img = new Image()
        img.src = compressorImageUrl
        img.onload = async () => {
          const ctx = canvas.getContext('2d')
          const rate = window.devicePixelRatio || 1
          canvas.width = img.width * rate
          canvas.height = img.height * rate
          ctx.fillStyle = '#ffffff'
          ctx.fillRect(0, 0, canvas.width, canvas.height)
          ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, img.width * rate, img.height * rate)
          ctx.save()
          const url = canvas.toDataURL('image/jpeg')
          const newFile = await this.base64toFilelet(url, file.name)
          const formData = new FormData()
          formData.append('file', newFile)
          this.$emit('update:loading', true)
          req.upload(this.action, formData)
              .then((res) => {
                this.uploadResponse && this.uploadResponse(res, file)
              })
              .catch(() => {
                this.handleRemove(file)
              })
              .finally(() => {
                this.$emit('update:loading', false)
                this.show = false
                this.loadingCrop = false
                this.cropperOption.img = ''
              })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.unShowUploadImg {
  ::v-deep .el-upload {
    display: none !important;
  }
}
.cropper-box {
  height: 70vh;
  width: 50vw;
  background: #fff;
  position: fixed;
  z-index: 9999;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  line-height: normal;
}
::v-deep {
  .el-upload {
    height: var(--height) !important;
    width: var(--width) !important;
  }
  .el-upload-list__item {
    height: var(--height) !important;
    width: var(--width) !important;
  }
}
</style>